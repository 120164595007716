import React from 'react'

const GithubIcon = ({ href }: { href: string }): JSX.Element =>
  <a href={href} target="_blank" rel="noopener noreferrer">
    <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
      <path d="m512 257c0 120-84.101562 220.5-196 247.5l-30.601562-97.199219h-58.796876l-29.601562 97.199219c-111.898438-27-197-127.5-197-247.5 0-140.699219 115.300781-257 256-257s256 116.300781 256 257zm0 0" fill="#384949" />
      <path d="m512 257c0 120-84.101562 220.5-196 247.5l-30.601562-97.199219h-29.398438v-407.300781c140.699219 0 256 116.300781 256 257zm0 0" fill="#293939" />
      <path d="m181.277344 430.058594c-6.078125 0-12.011719-.867188-17.828125-2.578125-15.128907-4.46875-27.421875-14.546875-36.546875-29.914063-4.160156-7.015625-8.496094-11.878906-13.605469-15.308594-5.027344-3.382812-9.039063-4.671874-13.273437-4.363281l-2.636719-29.882812c11.117187-.953125 21.753906 2.0625 32.59375 9.316406 8.832031 5.902344 16.257812 14.0625 22.71875 24.914063 5.304687 8.921874 11.410156 14.152343 19.25 16.46875 8.804687 2.589843 17.941406 1.507812 29.632812-3.472657l11.808594 27.566407c-11.296875 4.835937-21.929687 7.253906-32.113281 7.253906zm0 0" fill="#ececf1" />
      <path d="m400.902344 287.300781c-10.503906 27.898438-36.902344 63.300781-103.800782 73.199219 8.699219 12.898438 19.199219 19.800781 18.898438 46.800781v97.199219c-19.199219 4.800781-39.300781 7.5-60 7.5s-39.800781-2.699219-59-7.5v-98.402344c0-26.699218 10.101562-34.199218 17.898438-45.597656-66.898438-9.902344-93.296876-45.300781-103.800782-73.199219-14.097656-37.203125-6.597656-83.402343 18.003906-112.800781.597657-.601562 1.5-2.101562 1.199219-3-11.402343-34.199219 2.398438-62.699219 3-65.699219 12.898438 3.898438 15-3.902343 56.699219 21.597657l7.199219 4.203124c3 1.796876 2.101562.597657 5.101562.597657 17.398438-4.800781 35.699219-7.5 53.699219-7.5 18.300781 0 36.300781 2.699219 54.597656 7.5l2.101563.300781s.597656 0 2.101562-.898438c51.898438-31.503906 50.097657-21.300781 64.195313-25.800781.300781 3 14.101562 31.796875 2.703125 65.699219-1.5 4.5 45 47.097656 19.203125 115.800781zm0 0" fill="#ececf1" />
      <path d="m400.902344 287.300781c-10.503906 27.898438-36.902344 63.300781-103.800782 73.199219 8.699219 12.898438 19.199219 19.800781 18.898438 46.800781v97.199219c-19.199219 4.800781-39.300781 7.5-60 7.5v-387.300781c18.300781 0 36.300781 2.699219 54.601562 7.5l2.097657.300781s.601562 0 2.101562-.898438c51.898438-31.503906 50.097657-21.300781 64.199219-25.800781.300781 3 14.101562 31.796875 2.699219 65.699219-1.5 4.5 45 47.097656 19.203125 115.800781zm0 0" fill="#e2e2e7" />
    </svg>
  </a>

export default GithubIcon;
