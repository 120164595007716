import React from 'react'

const TwitterIcon = ({ href }: { href: string }): JSX.Element => (
  <a href={href} target="_blank" rel="noopener noreferrer">
    <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
      <path
        d="m512 60v362l-27.300781 30h-458.597657l-26.101562-30v-362zm0 0"
        fill="#faecd8"
      />
      <path
        d="m512 60v362l-27.300781 30h-228.699219v-392zm0 0"
        fill="#f4ce9f"
      />
      <path
        d="m451 0v362h-15c-104.398438 0-175.800781 86.398438-180 90v-392c42-39 101.101562-60 180-60zm0 0"
        fill="#f9d8b4"
      />
      <g fill="#f2bf81">
        <path d="m60 135h151v30h-151zm0 0" />
        <path d="m60 196h151v30h-151zm0 0" />
        <path d="m60 257h151v30h-151zm0 0" />
        <path d="m60 317h151v30h-151zm0 0" />
      </g>
      <path
        d="m512 422v30l-106 15-150 33-138.898438-33-117.101562-15v-30h157c35.097656 0 69 12 99 30 30-18 63.898438-30 99-30zm0 0"
        fill="#f4ce9f"
      />
      <path
        d="m512 422v30l-106 15-150 33v-48c30-18 63.898438-30 99-30zm0 0"
        fill="#f1c992"
      />
      <path
        d="m512 452v30h-157c-10.199219 0-23.398438 4.5-39.601562 14.097656-18 10.503906-38.398438 15.902344-59.398438 15.902344s-41.398438-5.398438-59.398438-15.902344c-16.203124-9.597656-29.402343-14.097656-39.601562-14.097656h-157v-30h157c15.898438 0 33.300781 5.699219 54.902344 18.300781 13.195312 7.5 28.496094 11.398438 44.097656 11.398438s30.902344-3.898438 44.097656-11.398438c21.601563-12.601562 39.003906-18.300781 54.902344-18.300781zm0 0"
        fill="#804231"
      />
      <path
        d="m512 452v30h-157c-10.199219 0-23.398438 4.5-39.601562 14.097656-18 10.503906-38.398438 15.902344-59.398438 15.902344v-30.300781c15.601562 0 30.902344-3.898438 44.097656-11.398438 21.601563-12.601562 39.003906-18.300781 54.902344-18.300781zm0 0"
        fill="#663325"
      />
    </svg>
  </a>
)

export default TwitterIcon
