export default [
  {
    'type': 'post',
    'path': '/post',
    'name': 'Post',
  },
  {
    'type': 'article',
    'path': '/article',
    'name': 'Article',
  },
]
