// Load Lato typeface
require('typeface-lato')
// Load Poor Story typeface
require('typeface-poor-story')
// Load Nanum Gothic Coding typeface
require('typeface-nanum-gothic-coding')
// Load Fira Sans typeface
require('typeface-fira-sans')
// Load Nanum Myeongjo typeface
require('typeface-nanum-myeongjo')
// Load Nanum Gothic typeface
require('typeface-nanum-gothic')
// Load Noto Sans KR typeface
require('typeface-noto-sans-kr')